<template>
    <div class="container-fluid">
        <div class="card search-card">
            <div class="card-body">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <div class="form-row">
                        
                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label for="ManpowerDemandNo">
                                工號
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="BadgeNo"
                                    placeholder="請輸入關鍵字"
                                    v-model="searchInfo.BadgeNo"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>姓名</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="請輸入關鍵字"
                                    v-model="searchInfo.Name"
                                />
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                                <label>起始日期</label>
                                <!-- <ValidationProvider
                                :rules="{ dateRangeRequired: true }"
                                v-slot="{ errors }"
                                > -->
                                <el-date-picker type="date" placeholder="請輸入日期" value-format="yyyy-MM-dd" class="date" v-model="searchInfo.Start">
                                </el-date-picker>
                                <!-- <date-picker
                                    class="w-100"
                                    type="date"
                                    range
                                    placeholder="YYYY-MM-DD~YYYY-MM-DD"
                                    v-model="applyDateRange"
                                /> -->
                                <!-- <span v-if="errors[0]" class="text-danger">
                                    {{ errors[0] }}
                                </span>
                                </ValidationProvider> -->
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                                <label>結束日期</label>
                                <!-- <ValidationProvider
                                :rules="{ dateRangeRequired: true }"
                                v-slot="{ errors }"
                                > -->
                                <el-date-picker type="date" placeholder="請輸入日期" value-format="yyyy-MM-dd" class="date" v-model="searchInfo.End">
                                </el-date-picker>
                                <!-- <date-picker
                                    class="w-100"
                                    type="date"
                                    range
                                    placeholder="YYYY-MM-DD~YYYY-MM-DD"
                                    v-model="applyDateRange"
                                /> -->
                                <!-- <span v-if="errors[0]" class="text-danger">
                                    {{ errors[0] }}
                                </span>
                                </ValidationProvider> -->
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-2">
                            <button
                                type="button"
                                class="btn btn-primary btn-search"
                                style="margin-top: 30px; margin-left: 10px"
                                @click="handleSubmit(getRestaurantCnrList(1, tableInfo.per_page))"
                            >
                                查詢
                            </button>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>

        <div class="yahome-table-wrap">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-info mb-2" @click="exportFile">匯出</button>
            </div>
            <VueBootstrap4Table
                :rows="rows"
                :columns="columns"
                :config="config"
                class="opening-table"
                @on-change-query="onChangeQuery"
                :total-rows="total"
            >
                <!-- 明細 -->
                <template slot="Detail">
                    <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#detailModal"
                    >
                        明細
                    </button>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <detailModal>
            <div class="form-group">
                <label>工號</label>
                <div class="detail-control">A6545</div>
            </div>
            <div class="form-group">
                <label>姓名</label>
                <div class="detail-control">張＊涵</div>
            </div>
            <div class="form-group">
                <label>座位號碼</label>
                <div class="detail-control">A棟_黃區</div>
            </div>
            <div class="form-group">
                <label>非指定座位原因</label>
                <div class="detail-control textarea"></div>
            </div>
            <div class="form-group">
                <label>其他建議</label>
                <div class="detail-control textarea"></div>
            </div>
        </detailModal>
    </div>
</template>

<script>
/* global $ */
import moment from "moment";
// import QrcodeVue from "qrcode.vue";
// 複製
// import Clipboard from "clipboard";

// import DemandDetailModal from "@/components/back/Modal/DemandDetail";

import { config } from "@/components/table_config.js";

import detailModal from '@/components/Modal/detailModal.vue'

import { apiGetRestaurantCnrList, apiExportRestaurantCnr } from '@/apis/questionnaire'
import { mapActions } from 'vuex'

export default {
    name: "OpeningManage",
    components: {
        detailModal
    },
    data() {
        return {
            rows: [],
            columns: [
                {
                    label: "填寫時間",
                    name: "CreateTime",
                },
                {
                    label: "工號",
                    name: "BadgeNo",
                    // sort: true,
                },
                {
                    label: "姓名",
                    name: "Name",
                },
                {
                    label: "區域",
                    name: "Area",
                },
                {
                    label: "座位號碼",
                    name: "SeatNo",
                },
                {
                    label: "非指定座位原因",
                    name: "Reason",
                },
                {
                    label: "其他建議",
                    name: "Suggest",
                },
                // {
                //     label: "檢視明細",
                //     name: "Detail",
                //     slot_name: "Detail",
                // },
            ],
            config: {
                ...config,
                server_mode: true,
            },
            searchInfo: {
                Start: '',
                End: '',
            },
            tableInfo: {
                // 分頁資訊
                page: 1,
                per_page: 10,
            },
            total: 0,
        };
    },
    filters: {
        moment: function(date) {
            return moment(date).format("YYYY-MM-DD");
        },
    },

    methods: {
        ...mapActions(['updateLoading']),
        ///////////////////////////  多國語系  ///////////////////////////////////////////
        i18n(keyStr) {
            return this.$t(keyStr);
        },
        getRestaurantCnrList(page = 1, per_page = 10) {

            this.updateLoading(true)
            apiGetRestaurantCnrList({
                ...this.searchInfo,
                Page: page,
                PerPage: per_page,
            }).then((response) => {
                // console.log(response)

                this.total = response.data.DataQuantity

                this.rows = response.data.Dtos.map(item => {
                    return {
                        ...item,
                        CreateTime: item.CreateTime !== null ? moment(item.CreateTime).format("YYYY-MM-DD HH:mm:ss") : null,
                    }
                })

                this.updateLoading(false)
            })
        },
        onChangeQuery(queryParams) {
            const vm = this;
            console.log('queryParams', queryParams)
            // console.log(JSON.parse(JSON.stringify(queryParams)));

            vm.tableInfo = {
                page: queryParams.page,
                per_page: queryParams.per_page,
            };

            vm.getRestaurantCnrList(
                vm.tableInfo.page,
                vm.tableInfo.per_page,
            );
        },
        exportFile() {

            this.updateLoading(true)
            apiExportRestaurantCnr({
                ...this.searchInfo,
            }).then((response) => {
                console.log(response)
                if(response.status === 200) {
                    const blob = new Blob([response.data], { 'type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = `餐廳實名制報表.xlsx`;
                    a.click();
                    window.URL.revokeObjectURL(url);

                    this.updateLoading(false)
                }
            })
        }
    },
    created() {
        this.searchInfo.Start = moment().subtract(7, 'days').format("YYYY-MM-DD");
        this.searchInfo.End = moment().format("YYYY-MM-DD");
    },
    mounted() {
        this.getRestaurantCnrList()
    },

    destroyed() {
        $(".modal").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
    },
};
</script>

<style lang="scss" scoped>
.detail-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.textarea {
    min-height: 140px;
}

.date {
    display: block;
}

.el-date-editor.el-input {
    width: 100%;
}
</style>
